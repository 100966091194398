<!--TODO ALGUNAS DE ESTAS COSAS DEBEN MOVERSE A MIXINS O SU PROPIO COMPONENTE PARA PODERLO RE UTILIZAR-->
<template>
    <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card tile>
            <v-toolbar color="primary" tile elevation="1">
                <v-btn icon @click="show = false">
                    <v-icon color="white">mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="py-6">
                <v-row>
                    <v-col cols="12">
                        <h3 class="primary--text display-1">Información Acumuladas</h3>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>

                    <v-col cols="12">
                        <v-layout column style="max-height: 40vh">
                            <v-flex md6 style="overflow: auto">
                                <v-data-table
                                    v-model="selectedProducts"
                                    :items="items"
                                    :headers="headers"
                                    :items-per-page="5"
                                    show-select
                                    disable-pagination
                                    hide-default-footer
                                    item-key="id"
                                    @toggle-select-all="handleToggleAll($event)"
                                >
                                    <template
                                        v-slot:item.volume="{ item }"
                                    >{{ item.volume | numberFormat }}</template>
                                    <template v-slot:item.client.name="{ item }">
                                        <v-btn
                                            text
                                            color="primary"
                                            :to="{
                                                name: 'detailClient',
                                                params: {
                                                    clientId: item.client.code,
                                                },
                                            }"
                                        >{{ item.client.name }}</v-btn>
                                    </template>
                                    <template v-slot:item.contribution_unit="{ item }">
                                        {{
                                            item.contribution_unit | toCurrency
                                        }}
                                    </template>
                                    <template
                                        v-slot:item.latest_price="{ item }"
                                    >{{ item.latest_price | toCurrency }}</template>
                                    <template
                                        v-slot:item.margin_unit="{ item }"
                                    >{{ item.margin_unit | numberFormat }}%</template>
                                </v-data-table>
                            </v-flex>
                        </v-layout>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="12">
                        <h3 class="primary--text display-1">Estadisticas por mes</h3>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="graphOption"
                            :items="opciones"
                            item-text="value"
                            label="Seleccione una opción"
                            :disabled="
                                selectedProducts.length === 0 || !graphOption
                            "
                            persistent-hint
                            return-object
                            single-line
                            v-on:change="graficas()"
                        ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" md="3">
            <v-select
          v-model="select"
          :items="fechas"
          item-text="name"
          item-value="name"
          label="Select"
          persistent-hint
          return-object
          single-line
           v-on:change="graficas()"
        ></v-select>
                    </v-col>-->
                </v-row>
            </v-card-text>
            <v-card-text class="py-6" v-show="!isHidden">
                <v-row>
                    <v-col cols="8">
                        <h3 class="primary--text display-1">
                            Estadísticas el año en curso
                            <small>{{ graphOption.value }}</small>
                        </h3>
                    </v-col>
                    <v-col cols="4">
                        <div class="text-right">
                            <v-btn text @click="printTable">
                                <v-icon dark>mdi-download</v-icon>
                            </v-btn>
                            <v-btn text @click="printExcel">
                                <v-icon dark>mdi-file-excel</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                        <!-- Hay otra forma de hacer esto pero.... que flojera :) -->
                        <v-data-table
                            :headers="headers2"
                            :items="productsData"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item.Uno="{ item }">
                                {{
                                    item["Uno"] ? formatterNumber(item["Uno"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Dos="{ item }">
                                {{
                                    item["Dos"] ? formatterNumber(item["Dos"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Tres="{ item }">
                                {{
                                    item["Tres"] ? formatterNumber(item["Tres"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Cuatro="{ item }">
                                {{
                                    item["Cuatro"] ? formatterNumber(item["Cuatro"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Cinco="{ item }">
                                {{
                                    item["Cinco"] ? formatterNumber(item["Cinco"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Seis="{ item }">
                                {{
                                    item["Seis"] ? formatterNumber(item["Seis"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Siete="{ item }">
                                {{
                                    item["Siete"] ? formatterNumber(item["Siete"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Ocho="{ item }">
                                {{
                                    item["Ocho"] ? formatterNumber(item["Ocho"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Nueve="{ item }">
                                {{
                                    item["Nueve"] ? formatterNumber(item["Nueve"]) : "-"
                                }}
                            </template>
                            <template v-slot:item.Diez="{ item }">
                                {{
                                    item["Diez"]
                                        ? formatterNumber(item["Diez"])
                                        : "-"
                                }}
                            </template>
                            <template v-slot:item.Once="{ item }">
                                {{
                                    item["Once"]
                                        ? formatterNumber(item["Once"])
                                        : "-"
                                }}
                            </template>
                            <template v-slot:item.Doce="{ item }">
                                {{
                                    item["Doce"]
                                        ? formatterNumber(item["Doce"])
                                        : "-"
                                }}
                            </template>
                            <template slot="body.append">
                                <tr>
                                    <th>Totales</th>
                                    <th>{{ sumField("Uno") }}</th>
                                    <th>{{ sumField("Dos") }}</th>
                                    <th>{{ sumField("Tres") }}</th>
                                    <th>{{ sumField("Cuatro") }}</th>
                                    <th>{{ sumField("Cinco") }}</th>
                                    <th>{{ sumField("Seis") }}</th>
                                    <th>{{ sumField("Siete") }}</th>
                                    <th>{{ sumField("Ocho") }}</th>
                                    <th>{{ sumField("Nueve") }}</th>
                                    <th>{{ sumField("Diez") }}</th>
                                    <th>{{ sumField("Once") }}</th>
                                    <th>{{ sumField("Doce") }}</th>
                                    <th>{{ sumField("sumatoria") }}</th>
                                    <th>{{ sumField("promedio") }}</th>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                    <!-- <v-col cols="12">
                        <v-chart
                            ref="chartData"
                            theme="walden"
                            class="ma-auto"
                            style="width: 100%"
                            :options="chartData"
                            autoresize
                        />
                    </v-col>-->
                </v-row>
            </v-card-text>
        </v-card>
        <v-card height="100%" v-show="!isHidden">
            <v-layout column style="height: 100vh">
                <v-flex md6 style="overflow: auto">
                    <v-chart
                        ref="chartData"
                        theme="walden"
                        class="ma-auto"
                        style="width: 100%"
                        :options="chartData"
                        autoresize
                    />
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import ECharts from "vue-echarts";

import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
    name: "StatisticsDialog",
    components: {
        "v-chart": ECharts,
    },
    props: {
        value: Boolean,
        years: String,
        types: Array,
    },
    data: () => ({
        productsData: [],
        isHidden: true,
        productsDetailDialog: false,
        opciones: [
            { id: 1, value: "Volumen" },
            { id: 2, value: "Facturación" },
            { id: 3, value: "Contribución" },
        ],
        select: { name: "2022" },
        fechas: [{ name: "2021" }, { name: "2022" }],

        graphOption: { id: 0, value: "---------" },
        selectedProducts: [],
        selectedProductsCopy: [],
        items: [],
        pdf: '',
        headers: [
            {
                text: "Cliente",
                value: "client.name",
            },
            {
                text: "SKU",
                value: "sku",
            },
            {
                text: "Producto",
                value: "name",
            },
            {
                text: "Precio por unidad",
                value: "latest_price",
            },
            {
                text: "Volumen",
                value: "volume",
            },
            // {
            //   text: "Facturación",
            //   value: "billed",
            // },
            {
                text: "Contribución por unidad",
                value: "contribution_unit",
            },
            {
                text: "Margen por unidad",
                value: "margin_unit",
            },
        ],
        headers2: [
            {
                text: "Modelo",
                value: "product",
            },
            {
                text: "Ene",
                value: "Uno",
            },
            {
                text: "Feb",
                value: "Dos",
            },
            {
                text: "Mar",
                value: "Tres",
            },
            {
                text: "Abr",
                value: "Cuatro",
            },
            {
                text: "May",
                value: "Cinco",
            },
            {
                text: "Jun",
                value: "Seis",
            },
            {
                text: "Jul",
                value: "Siete",
            },
            {
                text: "Ago",
                value: "Ocho",
            },
            {
                text: "Sep",
                value: "Nueve",
            },
            {
                text: "Oct",
                value: "Diez",
            },
            {
                text: "Nov",
                value: "Once",
            },
            {
                text: "Dic",
                value: "Doce",
            },
            {
                text: "Sumatoria",
                value: "sumatoria",
            },
            {
                text: "Promedio",
                value: "promedio",
            },
        ],
        chartData: {
            tooltip: {
                confine: true,
                trigger: "axis",
                axisPointer: {
                    // Use axis to trigger tooltip
                    type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: function (params) {
                    let output = "<b>" + params[0].name + "</b><br/>";
                    for (let i = 0; i < params.length; i++) {
                        output +=
                            params[i].marker +
                            params[i].seriesName +
                            ": " +
                            params[i].value;

                        if (i !== params.length - 1) {
                            // Append a <br/> tag if not last in loop
                            output += "<br/>";
                        }
                    }
                    return output;
                },
            },
            legend: {
                data: [],
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            toolbox: {
                feature: {
                    saveAsImage: { show: true, title: "Descargar imagen" }
                },
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: [
                    "Ene",
                    "Feb",
                    "Mar",
                    "Abr",
                    "May",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dic",
                ],
            },
            yAxis: {
                type: "value",
            },
            series: [],
        },
    }),
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted() {
        if (this.types.length > 0) {
            this.getDetail();
        }
    },
    methods: {
        handleToggleAll(event) {
            const toggle = event.value;
            if (toggle) {
                this.selectedProducts = this.items;
            } else {
                this.selectedProducts = [];
            }
        },
        closeDialog() {
            this.graphOption = this.opciones[0];
            this.productsDetailDialog = false;
        },
        getDetail() {
            this.$store.dispatch("working", true);
            console.log("ststics");
            console.log(this.types);
            this.$http
                .post("getTypesDetail", {
                    types: this.types,
                    year: this.years,
                })
                .then((res) => {
                    this.items = res.data.products;
                })
                .catch((error) => {
                    if (error.response) {
                        this.$toast.error(error.response.data.msg);
                    } else {
                        this.$toast.error("Ocurrio un error inesperado.");
                    }
                })
                .finally(() => {
                    this.$store.dispatch("working", false);
                });
        },
        printTable() {
            window.open(`${String(process.env.VUE_APP_API)}/storage/${this.pdf}`, '_blank')
        },
        printExcel() {
            window.open(`${String(process.env.VUE_APP_API)}/storage/tabla.xlsx`, '_blank')
        },
        graficas() {
            this.isHidden = false;
            this.$store.dispatch("working", true);
            this.productsDetailDialog = true;

            let typeG = "volume";

            if (this.graphOption.id === 1) {
                typeG = "volume";
            }
            if (this.graphOption.id === 2) {
                typeG = "billed";
            }
            if (this.graphOption.id === 3) {
                typeG = "contribution";
            }

            this.selectedProductsCopy = this.selectedProducts;
            //this.selectedProducts = [];

            const self = this;
            console.log("sssss"), console.log(this.selectedProductsCopy);
            this.$http
                .post("getProductsData", {
                    products: this.selectedProductsCopy,
                    year: this.years,
                    type: typeG,
                })
                .then((res) => {
                    res.data.products.forEach((current) => {
                        let acum = 0;
                        let max = 0;
                        acum = current['Uno'] + current['Dos'] + current['Tres'] + current['Cuatro'] + current['Cinco'] + current['Seis'] + current['Siete'] + current['Ocho'] + current['Nueve'] + current['Diez'] + current['Once'] + current['Doce'];
                        max = res.data.mes_actual
                        // for (let index = 1; index <= 12; index++) {
                        //     const tmpValue = parseInt(
                        //         current[index.toString()]
                        //     );
                        //     acum += isNaN(tmpValue) ? 0 : tmpValue;
                        //     //max += isNaN(tmpValue) ? 0 : 1;
                        //     max = res.data.mes_actual
                        // }
                        current["sumatoria"] = self.formatterNumber(acum);
                        //current["promedio"] = self.formatterNumber(parseFloat((acum / 12).toFixed(2)));
                        current["promedio"] = self.formatterNumber(
                            parseFloat((acum / max).toFixed(2))
                        );
                    });

                    this.productsData = res.data.products;
                    this.pdf = res.data.path_file
                    console.log(this.pdf)
                    let tooltipOptions = {};
                    let that = this;
                    if (this.graphOption.id === 1) {
                        tooltipOptions.formatter = function (params) {
                            let output = "<b>" + params[0].name + "</b><br/>";
                            let total = params.reduce(
                                (tot, b) =>
                                    tot + (b.value == null ? 0 : b.value),
                                0
                            );
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].value != null) {
                                    output +=
                                        params[i].marker +
                                        params[i].seriesName +
                                        ": " +
                                        that.$options.filters.numberFormat(
                                            params[i].value
                                        ) +
                                        "<br/>"; // : every 2nth
                                }
                                // output +=
                                //   params[i].marker +
                                //   params[i].seriesName +
                                //   ": " +
                                //   that.$options.filters.numberFormat(params[i].value); // : every 2nth

                                // if (i !== params.length - 1) {
                                //   // Append a <br/> tag if not last in loop
                                //   output += "<br/>";
                                // }
                            }
                            output +=
                                "<br/><br/>Total: " +
                                that.$options.filters.numberFormat(total);
                            return output;
                        };
                    }
                    if (
                        this.graphOption.id === 2 ||
                        this.graphOption.id === 3
                    ) {
                        typeG = "billed";
                        tooltipOptions.formatter = function (params) {
                            let output = "<b>" + params[0].name + "</b><br/>";
                            let total = params.reduce(
                                (tot, b) =>
                                    tot + (b.value == null ? 0 : b.value),
                                0
                            );
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].value != null) {
                                    output +=
                                        params[i].marker +
                                        params[i].seriesName +
                                        ": " +
                                        that.$options.filters.numberFormat(
                                            params[i].value
                                        ) +
                                        "<br/>"; // : every 2nth
                                }
                                // output +=
                                //   params[i].marker +
                                //   params[i].seriesName +
                                //   ": " +
                                //   that.$options.filters.toCurrency(params[i].value); // : every 2nth

                                // if (i !== params.length - 1) {
                                //   // Append a <br/> tag if not last in loop
                                //   output += "<br/>";
                                // }
                            }
                            output +=
                                "<br/><br/>Total: " +
                                that.$options.filters.toCurrency(total);
                            return output;
                        };
                    }

                    this.$refs.chartData.chart.setOption({
                        tooltip: {
                            ...tooltipOptions,
                        },
                        legend: {
                            data: res.data.legends,
                        },
                        series: res.data.series,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response) {
                        this.$toast.error(error.response.data.msg);
                    } else {
                        this.$toast.error("Ocurrio un error inesperado.");
                    }
                })
                .finally(() => {
                    this.$store.dispatch("working", false);
                });
        },
        sumField(key, formatter = true) {
            const value = this.productsData.reduce(
                (a, b) =>
                    parseFloat(
                        a.toString().replace("$", "").replaceAll(",", "")
                    ) +
                    parseFloat(
                        (b[key] || 0)
                            .toString()
                            .replace("$", "")
                            .replaceAll(",", "")
                    ),
                0
            );

            if (formatter) {
                return this.formatterNumber(value);
            } else {
                return new Intl.NumberFormat("es-MX").format(value);
            }
        },
        formatterNumber(value) {
            if (this.graphOption.id === 2 || this.graphOption.id === 3) {
                return this.$options.filters.toCurrency(value);
            } else {
                return this.$options.filters.numberFormat(value);
            }
        },
    },
};
</script>

<style scoped></style>
