<template>
  <v-container fluid>
    <!-- <v-row>
      <v-col cols="12">
        <h2 class="primary--text">Información Acumulada por modelo {{date}}</h2>
      </v-col>
    </v-row> -->
    <v-row>
       <v-col cols="12" md="2">
        <v-select
          v-model="select"
          :items="fechas"
          item-text="name"
          item-value="name"
          label="Seleccionar año"
          persistent-hint
          return-object
          single-line
          v-on:change="getProdCategories($event.name)"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedHeights"
          :items="heights"
          label="Altura"
          multiple
          item-value="id"
          item-text="height"
          @change="clickHeight"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon color="blue"> mdi-select-all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Elegir todo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.height }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ heights.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedFabrics"
          :items="fabrics"
          label="Tela"
          multiple
          item-value="id"
          item-text="name"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle2">
              <v-list-item-action>
                <v-icon color="blue"> mdi-select-all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Elegir todo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ fabrics.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="selectedPaints"
          :items="paints"
          label="Pintura"
          multiple
           :menu-props="{ left: true, offsetY: true }"
          item-value="id"
          item-text="name"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle3">
              <v-list-item-action>
                <v-icon color="blue"> mdi-select-all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Elegir todo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.name }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ paints.length - 1 }} others)
            </span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" md="2" class="text-center">
        <v-btn
          class="btn-filter"
          block
          color="primary"
          style=""
          @click="getProdCategories2"
          >Filtrar</v-btn
        >
        <v-btn
          v-if="
            selectedHeights.length > 0 ||
            selectedFabrics.length > 0 ||
            selectedPaints.length > 0
          "
          style="max-width: auto"
          block
          class="mt-2 btn-filter"
          color="primary"
          @click="clearFilters"
          >Limpiar</v-btn
        >
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mr-4"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-layout column style="max-height: 50vh">
          <v-flex md6 style="overflow: auto">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              fixed-header
              disable-pagination
              hide-default-footer
            >
            <!-- <template v-slot:item.name="{ item }">
          <img :src="'/assets/img/' + item.name" style="width: 50px; height: 50px" />
      </template> -->
              <template v-slot:item.volumen="{ item }">
                {{ item.volumen | numberFormat }}
              </template>
              <template v-slot:item.billed="{ item }">
                {{ item.billed | toCurrency }}
              </template>
              <template v-slot:item.contribution="{ item }">
                {{ item.contribution | toCurrency }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon color="primary" @click="viewStatistics(item)">
                  <v-icon>mdi-more</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-col>
    </v-row>

    <statistics-dialog
      :key="compKey"
      v-model="statisticsDialog"
      :types="selectedItems"
      :years="years"
    ></statistics-dialog>

    <filters-dialog
      :key="compKey + 1"
      v-model="showFiltersDialog"
      :types="selectedItems2"
      :selectedHeights="selectedHeights"
      :filter="filterBy"
      :years="years"
    ></filters-dialog>
  </v-container>
</template>

<script>
import StatisticsDialog from "@/views/Pages/product_performance/products/components/StatisticsDialog";
import FiltersDialog from "@/views/Pages/product_performance/products/components/FiltersDialog";

export default {
  name: "Product",
  components: { StatisticsDialog, FiltersDialog },
  data: () => ({
    search: "",
     years: "",
    compKey: 0,
    statisticsDialog: false,
    showFiltersDialog: false,
    select: { name: "2022" },
    fechas: [{ name: "2021" }, { name: "2022" }, { name: "2023" }],
    selectedItems: [],
    selectedItems2: [],
    filterBy: "",
    date: "",
    items: [],
    headers: [
      {
        text: "Modelos",
        value: "name",
      },
      {
        text: "Volumen",
        value: "volumen",
      },
      {
        text: "Facturación",
        value: "billed",
      },
      {
        text: "Contribución",
        value: "contribution",
      },
      {
        text: "Desglose",
        value: "actions",
      },
    ],
    selectedHeights: [],
    x:true,
    selectedPaints: [],
    selectedFabrics: [],
    heights: [],
    paints: [],
    fabrics: [],
  }),
  computed: {
    allHeights() {
      return this.selectedHeights.length === this.heights.length;
    },
    allPaints() {
      return this.selectedPaints.length === this.paints.length;
    },
    allFabrics() {
      return this.selectedFabrics.length === this.fabrics.length;
    },
  },
  mounted() {
    this.getFilters();
    this.getProdCategories("2022");
  },
  methods: {
    viewStatistics(item) {
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.statisticsDialog = true;
      this.years = this.select.name;
      this.compKey += 1;
    },
    toggle() {
      this.$nextTick(() => {
          console.log(this.x);
        if (this.allHeights) {
          this.selectedHeights = [];
          this.x = true;
        } else {
          this.selectedHeights = this.heights.map((a) => a);
          this.x = false;
        }
      });
    },
    toggle2() {
      this.$nextTick(() => {
        if (this.allFabrics) {
          this.selectedFabrics = [];
        } else {
          this.selectedFabrics = this.fabrics.map((a) => a.id);
        }
      });
    },
    toggle3() {
      this.$nextTick(() => {
        console.log(this.allPaints);
        if (this.allPaints) {
          this.selectedPaints = [];
        } else {
          this.selectedPaints = this.paints.map((a) => a.id);
        }
      });
    },
    getFilters() {
      this.$http
        .get("getFilters")
        .then((res) => {
          this.heights = res.data.heights;
          this.paints = res.data.paints;
          this.fabrics = res.data.fabrics;
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response.data.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
    getProdCategories(year) {
      this.$store.dispatch("working", true);
      this.$http
        .post("getProdCategories/"+ year, {
          heights: this.selectedHeights,
          paints: this.selectedPaints,
          fabrics: this.selectedFabrics,
        })
        .then((res) => {
          this.items = res.data.categories;
        this.date = res.data.date
          console.log("acax")
          console.log(res.data.date)
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response.data.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
    getProdCategories2() {
        console.log(this.select.name)
      this.$store.dispatch("working", true);
      this.years = this.select.name;
      this.$http
        .post("getProdCategorieswithFilters", {
            year: this.select.name,
          heights: this.selectedHeights,
          x:this.x,
          paints: this.selectedPaints,
          fabrics: this.selectedFabrics,
        })
        .then((res) => {
                    this.x = true;
          let _filters = [];
          if (this.selectedHeights.length > 0) _filters.push("altura");
          if (this.selectedFabrics.length > 0) _filters.push("tela");
          if (this.selectedPaints.length > 0) _filters.push("pintura");

          this.filterBy =
            _filters.length > 0 ? `Filtrados por (${_filters.join(", ")})` : "";

          this.selectedItems2 = res.data.categories;
          this.showFiltersDialog = true;
          this.compKey += 100;

          this.selectedHeights = [];
          this.selectedFabrics = [];
          this.selectedPaints = [];
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(error.response.data.msg);
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$store.dispatch("working", false);
        });
    },
    clearFilters() {
      this.selectedHeights = [];
      this.selectedFabrics = [];
      this.selectedPaints = [];
    },

    clickHeight() {
      console.log(this.selectedHeights);
      console.log(this.x);
    },
  },
};
</script>

<style scoped>
.btn-filter {
  max-width: auto !important;
  min-width: auto !important;
  width: auto !important;
}
</style>
