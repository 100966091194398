var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.fechas,"item-text":"name","item-value":"name","label":"Seleccionar año","persistent-hint":"","return-object":"","single-line":""},on:{"change":function($event){return _vm.getProdCategories($event.name)}},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.heights,"label":"Altura","multiple":"","item-value":"id","item-text":"height"},on:{"change":_vm.clickHeight},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-select-all")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Elegir todo")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.height))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.heights.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedHeights),callback:function ($$v) {_vm.selectedHeights=$$v},expression:"selectedHeights"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.fabrics,"label":"Tela","multiple":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle2}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-select-all")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Elegir todo")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.fabrics.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedFabrics),callback:function ($$v) {_vm.selectedFabrics=$$v},expression:"selectedFabrics"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.paints,"label":"Pintura","multiple":"","menu-props":{ left: true, offsetY: true },"item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle3}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v(" mdi-select-all")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Elegir todo")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.paints.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.selectedPaints),callback:function ($$v) {_vm.selectedPaints=$$v},expression:"selectedPaints"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"btn-filter",attrs:{"block":"","color":"primary"},on:{"click":_vm.getProdCategories2}},[_vm._v("Filtrar")]),(
          _vm.selectedHeights.length > 0 ||
          _vm.selectedFabrics.length > 0 ||
          _vm.selectedPaints.length > 0
        )?_c('v-btn',{staticClass:"mt-2 btn-filter",staticStyle:{"max-width":"auto"},attrs:{"block":"","color":"primary"},on:{"click":_vm.clearFilters}},[_vm._v("Limpiar")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-layout',{staticStyle:{"max-height":"50vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"},attrs:{"md6":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"fixed-header":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.volumen",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numberFormat")(item.volumen))+" ")]}},{key:"item.billed",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.billed))+" ")]}},{key:"item.contribution",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.contribution))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.viewStatistics(item)}}},[_c('v-icon',[_vm._v("mdi-more")])],1)]}}])})],1)],1)],1)],1),_c('statistics-dialog',{key:_vm.compKey,attrs:{"types":_vm.selectedItems,"years":_vm.years},model:{value:(_vm.statisticsDialog),callback:function ($$v) {_vm.statisticsDialog=$$v},expression:"statisticsDialog"}}),_c('filters-dialog',{key:_vm.compKey + 1,attrs:{"types":_vm.selectedItems2,"selectedHeights":_vm.selectedHeights,"filter":_vm.filterBy,"years":_vm.years},model:{value:(_vm.showFiltersDialog),callback:function ($$v) {_vm.showFiltersDialog=$$v},expression:"showFiltersDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }