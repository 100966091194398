var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"color":"primary","tile":"","elevation":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"py-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"primary--text display-1"},[_vm._v("Información Acumuladas")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{staticStyle:{"max-height":"40vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"},attrs:{"md6":""}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"items-per-page":5,"show-select":"","disable-pagination":"","hide-default-footer":"","item-key":"id"},on:{"toggle-select-all":function($event){return _vm.handleToggleAll($event)}},scopedSlots:_vm._u([{key:"item.volume",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numberFormat")(item.volume)))]}},{key:"item.client.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","to":{
                                            name: 'detailClient',
                                            params: {
                                                clientId: item.client.code,
                                            },
                                        }}},[_vm._v(_vm._s(item.client.name))])]}},{key:"item.contribution_unit",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.contribution_unit))+" ")]}},{key:"item.latest_price",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("toCurrency")(item.latest_price)))]}},{key:"item.margin_unit",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numberFormat")(item.margin_unit))+"%")]}}]),model:{value:(_vm.selectedProducts),callback:function ($$v) {_vm.selectedProducts=$$v},expression:"selectedProducts"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"primary--text display-1"},[_vm._v("Estadisticas por mes")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.opciones,"item-text":"value","label":"Seleccione una opción","disabled":_vm.selectedProducts.length === 0 || !_vm.graphOption,"persistent-hint":"","return-object":"","single-line":""},on:{"change":function($event){return _vm.graficas()}},model:{value:(_vm.graphOption),callback:function ($$v) {_vm.graphOption=$$v},expression:"graphOption"}})],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],staticClass:"py-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h3',{staticClass:"primary--text display-1"},[_vm._v(" Estadísticas el año en curso "),_c('small',[_vm._v(_vm._s(_vm.graphOption.value))])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.printTable}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-download")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.printExcel}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")])],1)],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.productsData,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.Uno",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Uno"] ? _vm.formatterNumber(item["Uno"]) : "-")+" ")]}},{key:"item.Dos",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Dos"] ? _vm.formatterNumber(item["Dos"]) : "-")+" ")]}},{key:"item.Tres",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Tres"] ? _vm.formatterNumber(item["Tres"]) : "-")+" ")]}},{key:"item.Cuatro",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Cuatro"] ? _vm.formatterNumber(item["Cuatro"]) : "-")+" ")]}},{key:"item.Cinco",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Cinco"] ? _vm.formatterNumber(item["Cinco"]) : "-")+" ")]}},{key:"item.Seis",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Seis"] ? _vm.formatterNumber(item["Seis"]) : "-")+" ")]}},{key:"item.Siete",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Siete"] ? _vm.formatterNumber(item["Siete"]) : "-")+" ")]}},{key:"item.Ocho",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Ocho"] ? _vm.formatterNumber(item["Ocho"]) : "-")+" ")]}},{key:"item.Nueve",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Nueve"] ? _vm.formatterNumber(item["Nueve"]) : "-")+" ")]}},{key:"item.Diez",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Diez"] ? _vm.formatterNumber(item["Diez"]) : "-")+" ")]}},{key:"item.Once",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Once"] ? _vm.formatterNumber(item["Once"]) : "-")+" ")]}},{key:"item.Doce",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item["Doce"] ? _vm.formatterNumber(item["Doce"]) : "-")+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Totales")]),_c('th',[_vm._v(_vm._s(_vm.sumField("Uno")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Dos")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Tres")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Cuatro")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Cinco")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Seis")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Siete")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Ocho")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Nueve")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Diez")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Once")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("Doce")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("sumatoria")))]),_c('th',[_vm._v(_vm._s(_vm.sumField("promedio")))])])])],2)],1)],1)],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHidden),expression:"!isHidden"}],attrs:{"height":"100%"}},[_c('v-layout',{staticStyle:{"height":"100vh"},attrs:{"column":""}},[_c('v-flex',{staticStyle:{"overflow":"auto"},attrs:{"md6":""}},[_c('v-chart',{ref:"chartData",staticClass:"ma-auto",staticStyle:{"width":"100%"},attrs:{"theme":"walden","options":_vm.chartData,"autoresize":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }